<template>
    <div>
        <b-field
            class="w-full"
            type="is-danger"
            :label="`${attribute.label ? attribute.label : ''} ${
                asterisk ? asterisk : ''
            }`"
        >
            <b-field
                type="is-info"
                :message="fileExtension"
            >
                <b-upload
                    v-model="dropFiles"
                    drag-drop
                    :multiple="!isOnePicture"
                    expanded
                    @input="showFiles"
                >
                    <section class="section">
                        <div class="content has-text-centered">
                            <p>
                                <b-icon icon="upload" size="is-large"></b-icon>
                            </p>
                            <p>Drop your files here or click to upload</p>
                        </div>
                    </section>
                </b-upload>
            </b-field>
        </b-field>
        <b-field :message="message" type="is-danger font-bold text-center">
        </b-field>
        <div
            class="tile is-ancestor"
            v-if="this.attribute.config.type === 'pictures'"
        >
            <div v-if="!isOnePicture">
                <div
                    v-for="(file, index) in result"
                    :key="index"
                    class="tile is-vertical is-one-third card"
                >
                    <div class="card-image">
                        <figure class="image is-4by3">
                            <img
                                alt="Placeholder image"
                                :src="
                                    file.encodedFile
                                        ? file.encodedFile
                                        : `${baseUrl}/storage/${file.id}?token=${token}`
                                "
                            />
                        </figure>
                    </div>
                    <div class="card-footer">
                        <a class="card-footer-item" href="#">{{ file.name }}</a>
                        <a
                            class="card-footer-item"
                            href="#"
                            @click.prevent="deleteDropFile(index)"
                            >Delete
                        </a>
                    </div>
                </div>
            </div>
            <div v-else-if="result" class="tile is-vertical is-one-third card">
                <div class="card-content">
                    <div class="media">
                        <figure class="image is-128x128 m-auto">
                            <img
                                alt="Placeholder image"
                                :src="
                                    result.encodedFile
                                        ? result.encodedFile
                                        : `${baseUrl}/storage${isLogo ? '/logo' : ''}/${result.id}?token=${token}`
                                "
                            />
                        </figure>
                    </div>
                </div>
                <div class="card-footer">
                    <a class="card-footer-item" href="#">{{
                        result.encodedFile ? result.name : result.name
                    }}</a>
                    <a
                        class="card-footer-item"
                        href="#"
                        @click.prevent="deleteDropFile()"
                        >Delete</a
                    >
                </div>
            </div>
        </div>

        <div v-else>
            <div
                class="tags"
                v-if="!isOnePicture && result && result.length >= 1"
            >
                <span
                    class="tag is-primary"
                    v-for="(file, index) in result"
                    :key="index"
                >
                    {{ file.name }}
                    <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                    ></button>
                </span>
            </div>
            <div class="tags" v-else>
                <span class="tag is-primary">
                    {{ result.name }}
                    <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile()"
                    ></button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Compressor from 'compressorjs'

export default {
    name: 'FormFileUpload',
    props: ['attribute', 'isOnePicture', 'isLogo'],
    data() {
        return {
            isFieldLogoVisible: false,
            asterisk: '',
            dropFiles: null,
            isPicturesUpload: 'pictures',
            min: this.attribute.config.min,
            max: this.attribute.config.max,
            maxWidth: 1200,
            maxHeight: 1200,
            message: '',
            result: null,
            baseUrl: this.$store.state.baseURL,
            token: this.$store.state.auth.token
        }
    },
    created() {
        if (this.isOnePicture) {
            this.dropFiles = null
            this.result =
                this.attribute.value.length > 0 ? this.attribute.value[0] : null
        } else {
            this.dropFiles = []
            this.result = []
            if (this.attribute.value && this.attribute.value.length > 0) {
                this.attribute.value.forEach((media) => {
                    this.result.push(media)
                })
            }
        }
    },
    watch: {
        result: function (newValue) {
            this.$emit('add_company_files', newValue)
        }
    },
    computed: {
        fileExtension: function () {
            return this.attribute.config['accepted-ext']
                ? 'Extensions de fichiers acceptées : ' +
                      this.attribute.config['accepted-ext'].join(', ')
                : ''
        }
    },
    methods: {
        deleteDropFile(index) {
            if (this.isOnePicture) {
                this.result = null
                this.dropFiles = null
            } else {
                this.result.splice(index, 1)
                this.dropFiles.splice(index, 1)
            }
        },
        showFiles(files) {
            if (this.isOnePicture) {
                this.result = null
                this.showFileshandler(files, this.isOnePicture)
            } else {
                this.result = []
                files.forEach((file) => {
                    if (this.result.length >= this.max) {
                        return
                    } else {
                        this.showFileshandler(file, this.isOnePicture)
                    }
                })
            }
        },
        showFileshandler(file, isOnePicture) {
            var fileExt = file.name.split('.').pop()
            var acceptedExt = this.attribute.config['accepted-ext']

            if (acceptedExt && !acceptedExt.includes(fileExt)) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `You can't add a ${fileExt} file. Please, refer to the accepted ext file list!`,
                    type: 'is-danger'
                })

                return
            }

            if (this.isPicturesUpload === true) {
                new Compressor(file, {
                    quality: 0.8,
                    maxWidth: this.maxWidth,
                    maxHeight: this.maxHeight,
                    success: (compressedImage) => {
                        this.encodeFileToBase64(compressedImage, isOnePicture)
                    },
                    error(e) {
                        console.log(e.message)
                    }
                })
            } else {
                this.encodeFileToBase64(file, isOnePicture)
            }
        },
        encodeFileToBase64(file, isOnePicture) {
            var fr = new FileReader()

            fr.onload = (e) => {
                if (isOnePicture) {
                    this.result = {
                        name: file.name,
                        ext: file.name.split('.').pop(),
                        encodedFile: e.target.result
                    }
                } else {
                    this.result.push({
                        name: file.name,
                        ext: file.name.split('.').pop(),
                        encodedFile: e.target.result
                    })
                }
            }
            fr.readAsDataURL(file)
        }
    }
}
</script>

<style scoped></style>
